<!--  -->
<template>
  <div class="container">
    <div class="title">
      <div class="title-name">消息中心</div>
      <div class="title-select">
        <div class="select-date">
          <p
            class="select-type"
            v-for="(item, i) in type"
            :key="i"
            :class="{ 'is-active': typeIndex === i }"
            @click="handleOrgChange(i)"
          >
            {{ item }}
          </p>
        </div>
        <div class="title-select-right">
          <div class="message-number">
            <el-badge is-dot></el-badge>
            <p>{{ unreadTotal }}条未读消息</p>
          </div>
          <div class="switch">
            <el-switch
              :value="switchData"
              active-text="只看未读"
              @change="handleChange"
            ></el-switch>
          </div>
          <el-button class="btn" :disabled="unreadTotal > 0 ? false : true" @click="handleAllRead">
            全部已读
          </el-button>
        </div>
      </div>
    </div>
    <div class="list" v-if="messageList.length" v-loading="loading">
      <div
        :class="item.notice_status === 1 ? 'list-item-no' : 'list-item'"
        v-for="item in messageList"
        :key="item.notice_id"
      >
        <div>
          <div :class="item.notice_status === 1 ? 'list-item-haveread' : 'list-item-unread'">
            <span v-if="item.task_type !== 'DCR'">【{{ item.task_type }}】 任务状态通知：</span>
            <span v-if="item.task_type !== 'DCR'">{{ item.task_status | formatStatus }}</span>
            <span v-if="item.task_type === 'DCR'">【{{ item.task_type }}】</span>
          </div>
          <div class="list-item-botttom" v-if="item.task_status === 3">
            <div class="list-item-botttom-message" v-if="item.task_type === '数据导入'">
              {{ item.task_type }}任务状态更新为：已完成。点此
              <el-button type="text" class="btn" @click="handleDownload(item)">
                下载导入结果
              </el-button>
              ，点此
              <el-button type="text" class="btn" @click="handleCheckImport(item)">
                查看导入数据
              </el-button>
            </div>
            <div class="list-item-botttom-message" v-else-if="item.task_type === '数据导出'">
              {{ item.task_type }}任务状态更新为：已完成。点此
              <el-button type="text" class="btn" @click="handleDownload(item)">
                下载导出结果
              </el-button>
            </div>
            <div class="list-item-botttom-message" v-else-if="item.task_type === '标签计算'">
              【{{ item.tag_name }}】{{ item.task_type }}任务状态更新为：执行成功。点此查看
              <el-button type="text" class="btn" @click="handleGoTag(item)">计算结果</el-button>
            </div>
            <div class="list-item-botttom-message" v-else>
              {{ item.task_type }}任务状态更新为：已完成。点此
              <el-button type="text" class="btn" @click="handleCheckMatch(item)">
                查看匹配结果
              </el-button>
            </div>
          </div>
          <div class="list-item-botttom" v-if="item.task_status === 4">
            <div class="list-item-botttom-message" v-if="item.task_type !== '标签计算'">
              {{ item.task_type }}任务状态更新为：{{
                item.task_status | formatStatus
              }}，失败原因：{{ item.fail_reson }}
            </div>
            <div class="list-item-botttom-message" v-else>
              【{{ item.tag_name }}】{{ item.task_type }}任务状态更新为：{{
                item.task_status | formatStatus
              }}，失败原因：{{ item.fail_reson }}
            </div>
          </div>

          <div class="list-item-botttom" v-if="item.task_type === 'DCR'">
            <div
              class="list-item-botttom-message"
              v-if="item.create_by !== 'admin' && item.dcr_operation === 1"
            >
              您已于{{ item.create_time.replace(/\-/g, '/') | formatTime }}成功创建DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              ,正在等待管理员处理。
            </div>
            <div
              class="list-item-botttom-message"
              v-if="item.create_by === 'admin' && role === 1 && item.dcr_operation === 1"
            >
              {{ item.dcr_create_user }}已于{{
                item.create_time.replace(/\-/g, '/') | formatTime
              }}成功创建DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              ,等待您处理。
            </div>
            <div
              class="list-item-botttom-message"
              v-if="
                uin === item.create_by && (item.dcr_operation === 2 || item.dcr_operation === 3)
              "
            >
              您已于{{ item.create_time.replace(/\-/g, '/') | formatTime }}创建的DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              ,已处理完成，处理结果为{{ item.dcr_operation | formatMessage }}。
            </div>
            <div
              class="list-item-botttom-message"
              v-if="
                uin !== item.create_by &&
                  role === 1 &&
                  (item.dcr_operation === 2 || item.dcr_operation === 3)
              "
            >
              您已处理完成，{{ item.create_time.replace(/\-/g, '/') | formatTime
              }}{{ item.dcr_create_user }}创建的DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              ,处理结果为{{ item.dcr_operation | formatMessage }}。
            </div>
            <!-- 高级版 -->
            <div
              class="list-item-botttom-message"
              v-if="uin === item.create_by && item.dcr_operation === 4"
            >
              您已于{{ item.create_time.replace(/\-/g, '/') | formatTime }}成功提交DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              数据验证，正在等待平台管理员处理。
            </div>
            <div
              class="list-item-botttom-message"
              v-if="uin !== item.create_by && role === 1 && item.dcr_operation === 4"
            >
              {{ item.dcr_create_user_company }}-{{ item.dcr_create_user }}已于{{
                item.create_time.replace(/\-/g, '/') | formatTime
              }}成功提交DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              数据验证，正在等待平台管理员处理。
            </div>

            <div
              class="list-item-botttom-message"
              v-if="
                uin === item.create_by && (item.dcr_operation === 5 || item.dcr_operation === 6)
              "
            >
              您已于{{ item.create_time.replace(/\-/g, '/') | formatTime }}提交验证的DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              已处理完成，处理结果为{{ item.dcr_operation | formatMessage }}。
            </div>
            <div
              class="list-item-botttom-message"
              v-if="
                uin !== item.create_by &&
                  role === 1 &&
                  (item.dcr_operation === 5 || item.dcr_operation === 6)
              "
            >
              您已处理完成，{{ item.create_time.replace(/\-/g, '/') | formatTime
              }}{{ item.dcr_create_user_company }}-{{ item.dcr_create_user }}提交的DCR
              <el-button @click="handleGoDcr(item)" class="btn" type="text">
                （{{ item.dcr_code }}）
              </el-button>
              数据验证，处理结果为{{ item.dcr_operation | formatMessage }}。
            </div>
          </div>
        </div>
        <div class="mark" v-if="item.notice_status === 2">
          <el-button type="text" @click="handleRead(item.notice_id, item.task_type)">
            标记为已读
          </el-button>
        </div>
      </div>
    </div>
    <div class="no-data" v-else>暂无消息</div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="20"
        :current-page="pageIndex"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { GetTaskNotices, ReadTaskNotices, GetDoctorDataMatchTaskInfo } from '@/api/message.js';
import { IsVip } from '@/api/dcr';
import papaparse from 'papaparse';
import { getBlob, downloadFile } from '@/utils/downloadExcel';
import login from '@/libs/login';
import { dateFormat } from '@/utils/util';
import { GetObjURL } from '@/api/upload';
export default {
  filters: {
    formatMessage(val) {
      if (val === 2 || val === 5) {
        return '接受';
      }
      if (val === 3 || val === 6) {
        return '拒绝';
      }
      return '';
    },
    formatStatus(val) {
      if (val === 3) {
        return '已完成';
      }
      if (val === 4) {
        return '执行失败';
      }
      return '';
    },
    formatTime(val) {
      if (val && !val.startsWith('0')) {
        return dateFormat(new Date(val), 'YYYY年MM月dd日');
      }
      return '';
    },
  },
  data() {
    return {
      isV: null,
      uin: '',
      type: ['全部类型', '数据导入', '数据导出', '数据匹配', '标签计算', 'DCR'],
      typeIndex: 0,
      switchData: false,
      pageIndex: 1,
      pageSizes: 20,
      messageList: [],
      total: 0,
      unreadTotal: 0,
      loading: false,
      doctorMatch: [],
    };
  },
  computed: {
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }
      return newRole;
    },
    // 判断管理端还是客户端
    systemEnv() {
      // systemEnv为1表示管理端，2表示客户端
      return localStorage.getItem('systemEnv');
    },
  },
  created() {
    if (this.systemEnv === '2' && this.role === 1) {
      IsVip({ service_name: 'dcr' })
        .then(res => {
          this.isV = res.is_vip;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
    this.getMessages();
    this.getUnreadMessage();
    this.uin = login.getToken().uin;
  },
  methods: {
    // 跳转DCR详情
    handleGoDcr(item) {
      const href = this.$router.resolve({
        path: '/dashboard/dcrRecord-detail',
        query: {
          id: item.dcr_id,
        },
      });
      window.open(href.href, '_blank');
    },
    // 消息全部已读
    handleAllRead() {
      this.handleRead();
    },
    // 查看数据匹配结果
    handleCheckMatch(item) {
      console.log(item);
      this.handleMatch(item.task_id);
    },
    // 查看导入数据
    handleCheckImport(item) {
      console.log(item);
      if (item.data_type === 'jurisdiction_data') {
        const href = this.$router.resolve({
          path: '/dashboard/import-export',
          query: {
            type: '辖区',
          },
        });
        window.open(href.href, '_blank');
      } else {
        const href = this.$router.resolve({
          path: '/dashboard/import-export',
          query: {
            id: item.task_id,
            type: item.data_type === 't_hco' ? '机构' : '医疗专业人士',
          },
        });
        window.open(href.href, '_blank');
      }
    },
    // 设置已读消息方法
    handleRead(id = '', type = '') {
      const arr = [];
      if (id) {
        arr.push(id);
      }
      const req = {
        notice_ids: arr,
        task_type: type,
        task_status: 0,
        time_interval: [],
      };
      ReadTaskNotices(req)
        .then(() => {
          this.$message.success('消息成功已读');
          if (this.typeIndex === 0) {
            if (this.switchData) {
              this.getMessages('', 2);
              this.getUnreadMessage();
            } else {
              this.getMessages();
              this.getUnreadMessage();
            }
          }
          if (this.typeIndex === 1) {
            if (this.switchData) {
              this.getMessages('数据导入', 2);
              this.getUnreadMessage();
            } else {
              this.getMessages('数据导入', 0);
              this.getUnreadMessage();
            }
          }
          if (this.typeIndex === 2) {
            if (this.switchData) {
              this.getMessages('数据导出', 2);
              this.getUnreadMessage();
            } else {
              this.getMessages('数据导出', 0);
              this.getUnreadMessage();
            }
          }
          if (this.typeIndex === 3) {
            if (this.switchData) {
              this.getMessages('医生数据匹配', 2);
              this.getUnreadMessage();
            } else {
              this.getMessages('医生数据匹配', 0);
              this.getUnreadMessage();
            }
          }
          if (this.typeIndex === 4) {
            if (this.switchData) {
              this.getMessages('标签计算', 2);
              this.getUnreadMessage();
            } else {
              this.getMessages('标签计算', 0);
              this.getUnreadMessage();
            }
          }
          if (this.typeIndex === 5) {
            if (this.switchData) {
              this.getMessages('DCR', 2);
              this.getUnreadMessage();
            } else {
              this.getMessages('DCR', 0);
              this.getUnreadMessage();
            }
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    // 下载结果
    handleDownload(row) {
      console.log(row);
      GetObjURL({ url: row.file_url })
        .then(res => {
          if (row.file_name.endsWith('.csv')) {
            // 下载csv文件
            papaparse.parse(res.data.url, {
              download: true,
              complete(results, file) {
                console.log('Parsing complete:', results, file);
                if (results?.data) {
                  const csv = papaparse.unparse(results.data);
                  // console.log('downLoad', csv);
                  const content = new Blob([`\ufeff${csv}`], { type: 'text/plain;charset=utf-8' });
                  const urlObject = window.URL || window.webkitURL || window;
                  const url = urlObject.createObjectURL(content);
                  const el = document.createElement('a');
                  el.href = url;
                  el.download = row.file_name;
                  el.click();
                  urlObject.revokeObjectURL(url);
                }
              },
            });
          } else {
            // 下载excel文件
            getBlob(res.data.url, function(blob) {
              downloadFile(blob, row.file_name);
            });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    // 选择一页的条数
    handleSizeChange(val) {
      this.pageSizes = val;
      this.handleSelectChange();
      this.getUnreadMessage();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.handleSelectChange();
      this.getUnreadMessage();
    },
    // 选择消息类型
    handleOrgChange(val) {
      console.log(val);
      if (val !== this.typeIndex) {
        this.typeIndex = val;
        this.handleSelectChange();
      }
    },
    // 选择和翻页逻辑
    handleSelectChange() {
      if (this.typeIndex === 0) {
        if (this.switchData) {
          this.getMessages('', 2);
        } else {
          this.getMessages();
        }
      }
      if (this.typeIndex === 1) {
        if (this.switchData) {
          this.getMessages('数据导入', 2);
        } else {
          this.getMessages('数据导入', 0);
        }
      }
      if (this.typeIndex === 2) {
        if (this.switchData) {
          this.getMessages('数据导出', 2);
        } else {
          this.getMessages('数据导出', 0);
        }
      }
      if (this.typeIndex === 3) {
        if (this.switchData) {
          this.getMessages('医生数据匹配', 2);
        } else {
          this.getMessages('医生数据匹配', 0);
        }
      }
      if (this.typeIndex === 4) {
        if (this.switchData) {
          this.getMessages('标签计算', 2);
        } else {
          this.getMessages('标签计算', 0);
        }
      }
      if (this.typeIndex === 5) {
        if (this.switchData) {
          this.getMessages('DCR', 2);
        } else {
          this.getMessages('DCR', 0);
        }
      }
    },
    // 只看未读消息开关
    handleChange(val) {
      if (val) {
        this.switchData = true;
        if (this.typeIndex === 0) {
          this.getMessages('', 2);
        }
        if (this.typeIndex === 1) {
          this.getMessages('数据导入', 2);
        }
        if (this.typeIndex === 2) {
          this.getMessages('数据导出', 2);
        }
        if (this.typeIndex === 3) {
          this.getMessages('医生数据匹配', 2);
        }
        if (this.typeIndex === 4) {
          this.getMessages('标签计算', 2);
        }
        if (this.typeIndex === 5) {
          this.getMessages('DCR', 2);
        }
      } else {
        this.switchData = false;
        if (this.typeIndex === 0) {
          this.getMessages('', 0);
        }
        if (this.typeIndex === 1) {
          this.getMessages('数据导入', 0);
        }
        if (this.typeIndex === 2) {
          this.getMessages('数据导出', 0);
        }
        if (this.typeIndex === 3) {
          this.getMessages('医生数据匹配', 0);
        }
        if (this.typeIndex === 4) {
          this.getMessages('标签计算', 0);
        }
        if (this.typeIndex === 5) {
          this.getMessages('DCR', 0);
        }
      }
    },
    // 获取消息列表
    getMessages(type = '', status = 0) {
      this.loading = true;
      const req = {
        task_type: type,
        task_status: 0,
        notice_status: status,
        time_interval: [],
        index: this.pageIndex,
        page_size: this.pageSizes,
      };
      GetTaskNotices(req)
        .then(res => {
          console.log('消息列表', res);
          this.messageList = res.notices;
          this.total = res.total;
          this.loading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 获取全部未读消息数量
    getUnreadMessage() {
      const req = {
        task_type: '',
        task_status: 0,
        notice_status: 2,
        time_interval: [],
        index: -1,
        // page_size: '',
      };
      GetTaskNotices(req)
        .then(res => {
          console.log('全部未读', res);
          this.unreadTotal = res.total;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 获取数据匹配的单条数据
    handleMatch(id) {
      const req = {
        task_id: id,
      };
      GetDoctorDataMatchTaskInfo(req)
        .then(res => {
          console.log('数据匹配', res);
          const arr = [];
          arr.push(res.info);
          this.doctorMatch = arr.map(item => {
            if (item.status === 1) {
              return { ...item, status: '排队中' };
            }
            if (item.status === 2) {
              return { ...item, status: '执行中' };
            }
            if (item.status === 3) {
              return { ...item, status: '已完成' };
            }
            if (item.status === 4) {
              return { ...item, status: '任务失败' };
            }
          });
          const href = this.$router.resolve({
            path: '/dashboard/doctor-match-result',
            query: {
              create_time: this.doctorMatch[0].create_time,
              create_user: this.doctorMatch[0].create_user,
              end_time: this.doctorMatch[0].end_time,
              remark: this.doctorMatch[0].remark,
              status: this.doctorMatch[0].status,
              task_id: this.doctorMatch[0].task_id,
              file_name: this.doctorMatch[0].file_name,
              file_url: this.doctorMatch[0].file_url,
            },
          });
          window.open(href.href, '_blank');
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    handleGoTag(item) {
      const href = this.$router.resolve({
        path: '/dashboard/tag-manage-detail',
        query: {
          id: item.tag_id,
        },
      });
      window.open(href.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
